<template>
  <div class="no-padding">

    <v-row class="mt-4" v-if="local_item">
      <v-col>

        <memlist-select-input 
          id="group_id"
          name="group_id"
          title=""
          layout="vertical"
          validation_type="TEXT"

          v-model="local_item.group_id"
          :items="group_options"
          :validate="force_validate"
          :placeholder="$t('MEMBER_PROPERTY.GROUP_NAME')"
          :required="false"
          :invalid_text="''"
          :info_text="''"
          @validated="validated"
          @update:value="update_group_id"
        />

      </v-col>
      <v-col>

        <memlist-select-input 
          id="prop_id"
          name="prop_id"
          title=""
          layout="vertical"
          validation_type="TEXT"

          v-model="local_item.prop_id"
          :items="property_options"
          :placeholder="$t('MEMBER_PROPERTY.PROP_NAME')"
          :required="false"
          :invalid_text="''"
          :info_text="''"
          @validated="validated"
          @update:value="update_prop_id"
        />
      </v-col>

      <v-col>
        
        <a
          class="btn btn-icon btn-light btn-sm"
          @click.prevent="delete_row_clicked()"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          </span>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';


export default {

  name: 'AttributeSelectRow',

  props: ['item','groups'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    
  },

  watch: {
    
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        if (!this.local_item) {
          this.local_item = {};
        }

        if (this.local_item.group_id) {
          const group = this.groups.find(item => item.group_id === this.local_item.group_id);

          if (group) {
            let options = group.properties.map((item) => ({ text: item.name, value: item.prop_id }));

            options = options.sort((a, b) => {
              return a.text.localeCompare(b.text);
            });

            this.property_options = options;
          }
        }        
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    group_options() {
      
      let options = this.groups.map(item => ({ text: item.name, value: item.group_id }))

      options = options.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

      return options;
    },
    
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }
    
    if (!this.local_item) {
      this.local_item = {};
    }

    if (this.local_item.group_id) {
      console.log('mounted with group ids', this.groups);

      const group = this.groups.find(item => item.group_id === this.local_item.group_id);

      if (group) {
        console.log('group found')
        let options = group.properties.map((item) => ({ text: item.name, value: item.prop_id }));

        options = options.sort((a, b) => {
          return a.text.localeCompare(b.text);
        });

        this.property_options = options;
      }
    }
    
  },

  methods: {
    validated(field, name, valid) {},
    delete_row_clicked() {
      this.$emit('deleted', this.local_item.index);
    },
    update_group_id(field, group_id) {
      this.local_item.group_id = group_id;

      this.local_item = { ...this.local_item };

      const group = this.groups.find(item => item.group_id === group_id);

      if (group) {
        let options = group.properties.map((item) => ({ text: item.name, value: item.prop_id }));

        options = options.sort((a, b) => {
          return a.text.localeCompare(b.text);
        });

        this.property_options = options;
      }

      this.$emit('updated', this.local_item);
    },
    update_prop_id(field, prop_id) {
      this.local_item.prop_id = prop_id;

      this.local_item = { ...this.local_item };

      this.$emit('updated', this.local_item);
    },
  },


  data() {
    return {
      local_item: {},
      property_options: [],
      force_validate: false
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_table.scss";
@import "@/assets/sass/components/forms/_common_modals.scss";

:deep .v-label {
  left: 9px;
  top: 3px;
}

</style>


<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <p>{{ $t('FORM.SELECT_QUESTIONS') }}</p>

      <AttributeGroupSelectButton
        :group_ids="local_item.group_ids"
        @selected="property_groups_selected"
      />
      
      <p class="mt-4"><a href="/ml-properties">{{ $t('MEMBER_PROPERTY.ADMINISTER') }} >></a></p>
      
    </div>
  </div>
</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import AttributeGroupSelectButton from '@/view/components/attribute_group_selector/AttributeGroupSelectButton.vue';

export default {

  name: 'FormPageQuestionsEditor',

  props: ['item','validate'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    AttributeGroupSelectButton
  },

  watch: {
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.force_validate = this.force_validate + 1;
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

  },

  methods: {

    property_groups_selected(group_ids) {
      this.local_item.group_ids = group_ids;
      this.local_item = { ...this.local_item }

      this.$emit('change', this.local_item);
    },


    validate() {
      this.force_validate = this.force_validate + 1;
    },

    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      local_item: {},

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>

<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <v-row style="margin-top: 22px !important;">
        <v-col md="6">

          <memlist-select-input 
            id="type"
            name="type"
            :title="$t('FORM.ASSIGN_COMPANY')"
            layout="vertical"
            validation_type="TEXT"

            v-model="local_item.company_id"
            :items="company_options"
            :placeholder="$t('FORM.ASSIGN_COMPANY')"
            :required="true"
            :invalid_text="''"
            :info_text="''"
            @update:value="update_company"
          />

        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

export default {

  name: 'FormRuleCompanyEditor',

  props: ['item'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    
  },

  watch: {
    
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {

        this.local_item = { ...this.item };

      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'customer', 'companies']),

    company_options() {
      return this.companies.map(item => ( { text: item.name, value: item.company_id } ));
    },
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }
    else {
      this.local_item = {};
    }
  
  },

  methods: {

    update_company(field, company_id) {
      this.local_item.company_id = company_id;
      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },
    
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.added) {
          return 'bg-success';
        }
      }
      return null;
    },
    
    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      local_item: {},
      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_table.scss";
@import "@/assets/sass/components/forms/_common_modals.scss";

:deep .v-label {
  left: 9px;
  top: 3px;
}

</style>


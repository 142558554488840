<template>
  <Bar :chartData="stat_chart_data" :chartOptions="bar_option" />
</template>

<script>
import { registerables } from 'chart.js';
import { Chart } from 'chart.js';
import { Bar } from 'vue-chartjs'

Chart.register(...registerables);

export default {
  components: { Bar },
  props: {
    /*
    [
      {
        "day": "2024-09-08",
        "done_ok": 0,
        "done_error": 0,
        "first": 1
      },
      {
        "day": "2024-09-09",
        "done_ok": 0,
        "done_error": 0,
        "first": 0
      }
    ]
    */
    chart_data: { type: Object, default: [] },
    options: { type: Object, default: null }
  },
  computed: {
    stat_chart_data: function() {

      if (!this.chart_data || !Array.isArray(this.chart_data)) {
        return {
          is_loading: true,
          data_loaded: false
        };
      }

      return {
        is_loading: false,
        data_loaded: false,
        labels: this.chart_data.map(item => (item.day.substring(5, 7) + '/' + item.day.substring(8))),
        datasets: [
          {
            label: this.$t('FORM.FORM_VISIT_TYPES.FIRST'),
            backgroundColor: '#5d78ff',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: this.chart_data.map(item => (item.first))
          },
          {
            label: this.$t('FORM.FORM_VISIT_TYPES.DONE_OK'),
            backgroundColor: '#00dd00',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: this.chart_data.map(item => (item.done_ok))
          },
          {
            label: this.$t('FORM.FORM_VISIT_TYPES.DONE_ERROR'),
            backgroundColor: '#ff8888',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: this.chart_data.map(item => (item.done_error))
          }
        ]
      };
    },
  },
  data() {
    return {

      bar_option: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        hover: {
          mode: 'index'
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    }
  }
};
</script>

<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <v-row v-if="local_item && local_item.fields">
        <v-col md="6">
          <v-checkbox value="true" v-model="required_personnr" :label="$t('PUBLIC.FORM.PERSONNR_REQUIRED')"></v-checkbox>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import dayjs from 'dayjs';


export default {

  text: 'FormPagePersonnrEditor',

  props: ['item','validate'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    
  },

  watch: {
    required_personnr(newValue) {
      this.local_item.fields.in_personnr.required = newValue === 'true';
      this.local_item = { ...this.local_item };
      this.$emit('change', this.local_item);
    },
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.force_validate = this.force_validate + 1;
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        if (!this.local_item.fields) {
          this.local_item.fields = {
            in_personnr: {
              required: false
            }
          };
        }
        else {
          this.required_personnr = this.local_item.fields.in_personnr.required ? 'true' : null;
        }
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    
    
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }

      if (!this.local_item.fields) {
        this.local_item.fields = {
          in_personnr: {
            required: false
          }
        };
      }
      else {
        this.required_personnr = this.local_item.fields.in_personnr.required ? 'true' : null;
      }

      /**
       * fields = {
       *   field_text: {
       *     show: true,
       *     required: false
       *   }
       * }
       */
    }
    
  },

  methods: {

    validate() {
      this.force_validate = this.force_validate + 1;
    },

    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      local_item: {},

      required_personnr: null,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

:deep .v-label {
  left: 9px;
  top: 3px;
}

</style>

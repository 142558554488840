<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">

    <FormPageConfirmTermEditorModal 
      :item="local_term_item"
      ref="form-edit-modal"
      @created="terms_created"
      @updated="terms_updated"
    />
    
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">{{$t('PAGES.TERMS.TERMS')}}</span>
      </h3>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mb-8"
          @click="create_term_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>{{$t('PAGES.TERMS.NEW')}}</a
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless table-striped">
          <thead>
            <tr class="text-left">
              <th>{{$t('COMMON.ACTIVE')}}</th>
              <th>{{$t('COMMON.NAME')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in terms">
              <FormPageConfirmTermsTableRow
                v-bind:key="i"
                :item="item"
                @select_term_clicked="select_term_clicked"
                @delete_term_clicked="delete_term_clicked"
                @is_active_changed="is_active_changed"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from 'axios';

import FormPageConfirmTermEditorModal from '@/view/pages/ml/forms/pages/confirm/FormPageConfirmTermEditorModal.vue';
import FormPageConfirmTermsTableRow from '@/view/pages/ml/forms/pages/confirm/FormPageConfirmTermsTableRow.vue';
import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import { mapGetters } from 'vuex';
import { updateTerms } from '@/core/services/initUserMemlist';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'FormPageConfirmTermsTable',
  props: ['page_item'],
  emits: ['term_selected'],
  mixins: [ toasts ],

  components: {
    FormPageConfirmTermsTableRow,
    TiptapVuetify,
    FormPageConfirmTermEditorModal
  },

  computed: {
    ...mapGetters(['currentCompanyId','customer'])
  },

  watch: {
    page_item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_page_item = { ...this.page_item };

        if (!this.local_page_item.fields) {
          this.local_page_item.fields = {
            terms: []
          };

          this.local_page_item = { ...this.local_page_item };
        }
      }
    }
  },

  async mounted() {

    this.local_page_item = { ...this.page_item }

    if (!this.local_page_item.fields) {
      this.local_page_item.fields = {
        terms: []
      };

      this.local_page_item = { ...this.local_page_item };
    }
    
    await this.load_terms();
  },

  methods: {

    terms_created(item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.CREATED'));

      this.load_terms();
    },

    terms_updated(item) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.UPDATED'));

      this.load_terms();
    },

    async load_terms() {
      try {
        
        const res = await axios.get(`/customer/${this.customer.id}/templates`)

        if (res.status === 200) {
          this.terms = res.data;

          this.terms = this.terms.filter(item => item.is_terms && item.audience === 'PUBLIC');

          for (let i = 0; i < this.terms.length; i++) {
            const t = this.terms[i];
            const page_term = this.local_page_item.fields.terms.find(item => item.id === t.id);

            console.log('finding terms', this.local_page_item.fields.terms)

            if (page_term) {
              this.terms[i].is_active = page_term.is_active;
            }
            else {
              this.terms[i].is_active = false;
            }

          }

          this.terms = [ ...this.terms ]
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_GET'));
        }
      }
      catch (err) {
        console.error('load_terms error', err);
      }
    },

    create_term_clicked() {
      this.local_term_item = {
        name: 'Nytt villkor',
        descr: 'Fyll i text att stå vid checkbox',
        is_mandatory: false,
        company_id: this.currentCompanyId,
        audience: 'PUBLIC'
      }

      this.$refs['form-edit-modal'].show();

    },

    async is_active_changed(id, is_active) {

      console.log('is_active_changed', id, is_active)

      const found = this.local_page_item.fields.terms.find(item => item.id === id);

      if (found) {
        found.is_active = is_active;
      }
      else {
        this.local_page_item.fields.terms.push({
          id: id,
          is_active: is_active
        });
      }

      this.local_page_item = { ...this.local_page_item };

      this.$emit('changed', this.local_page_item);

    },

    
    async select_term_clicked(id) {
      
      this.local_term_item = await this.get_term(id);

      this.$refs['form-edit-modal'].show();
    },

    async delete_term_clicked(id) {

      await this.delete_term(id);

    },

    async get_term(id) {
      try {
        const res = await axios.get(`/company/term/${id}`);

        if (res.status === 200) {
          return res.data;
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_GET'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_GET'));
      }
    },

    async post_term(data) {
      try {
        const res = await axios.post(`/company/term/${this.currentCompanyId}`, data);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.CREATED'));
          this.load_terms();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_CREATE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_CREATE'));
      }
    },

    async put_term(id, term) {
      try {
        const res = await axios.put(`/company/term/${id}`, term);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.UPDATED'));
          this.load_terms();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
      }
    },

    async delete_term(id) {
      try {
        const res = await axios.delete(`/company/term/${id}`);

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.TERMS.DELETED'));
          this.load_terms();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_DELETE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_DELETE'));
      }
    }

  },

  data() {
    return {
      local_term_item: {},
      local_page_item: {},

      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      
      terms: [], // list of all terms

    };
  }
};
</script>

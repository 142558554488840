<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <b-modal size="xl" v-if="local_item.company_hierarchy && local_item.texts" ref="company-select-modal">
        <v-tabs background-color="white" color="accent-4" left>
          <v-tab>{{ $t('FORM.SETTINGS') }}</v-tab>
          <v-tab v-if="local_item.id">{{ $t('FORM.TEXTS_TAB') }}</v-tab>
          
          <v-tab-item>
            <FormPageCompanySelectHierarchyComponent
              :company_hierarchy="local_item.company_hierarchy"
              :form_page="local_item"
              @change="hierarchy_changed"
            />
          </v-tab-item>

          <v-tab-item>
            <FormPageCompanySelectTextsComponent
              :texts="local_item.texts"
              @change="texts_changed"
            />
          </v-tab-item>
        </v-tabs>

        
      </b-modal>

      <p>{{ $t('FORM.SELECT_COMPANIES') }}</p>

      <b-button
        class="w-50"
        @click="open_modal"
        block
        variant="outline-primary">{{ $t('FORM.COMPANIES_WITH_MEMBERSHIPS', { count: counted_children }) }}</b-button>
      
      <p class="mt-4"><a href="/ml-subcompany">{{ $t('FORM.COMPANY_ADMINISTER') }} >></a></p>
      
    </div>
  </div>
</template>


<script>

import FormPageCompanySelectHierarchyComponent from '@/view/pages/ml/forms/pages/FormPageCompanySelectHierarchyComponent.vue';
import FormPageCompanySelectTextsComponent from '@/view/pages/ml/forms/pages/FormPageCompanySelectTextsComponent.vue';


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

export default {

  name: 'FormPageCompanySelectEditor',

  props: ['item','validate','form'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    FormPageCompanySelectHierarchyComponent,
    FormPageCompanySelectTextsComponent
  },

  watch: {
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.force_validate = this.force_validate + 1;
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        this.counted_children = 0;
        this.count_hierarchy(this.local_item.company_hierarchy);
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }
    else {
      this.local_item = {}
    }

    this.counted_children = 0;

    this.count_hierarchy(this.local_item.company_hierarchy);

  },

  methods: {

    hierarchy_changed(company_hierarchy) {
      this.local_item.company_hierarchy = company_hierarchy;
      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    texts_changed(texts) {
      this.local_item.texts = texts;
      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    count_hierarchy(parent) {
      
      if (parent.children) {
        parent.children.forEach(child => {
          this.count_hierarchy(child);
        });
      }

      if (parent.membership) {
        this.counted_children += 1;
      }
    },

    async create_form_page(item) {
      try {
        const response = await axios.post(`/form/${this.form.id}/page`, this.local_item);

        if (response.status === 201) {
          this.local_item = response.data;

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_CREATED'));

          return this.local_item;
        }
      }
      catch (err) {
        console.error('create_form_page', err);
      }

      return null;
    },


    async open_modal() {

      if (!this.local_item.id) {
        // when user clicks on the company select assignment, we need to make sure we have created the page
        const created_item = await this.create_form_page(this.local_item);

        if (created_item) {
          this.$emit('created', created_item);

          this.$nextTick(()=>{
            this.$refs['company-select-modal'].show();
          });
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORM.PAGE_NOT_CREATED'));
        }
      }
      else {
        this.$nextTick(()=>{
          this.$refs['company-select-modal'].show();
        });
      }
    },

    
    validate() {
      this.force_validate = this.force_validate + 1;
    },

    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      counted_children: 0,

      local_item: {},

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>

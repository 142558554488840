<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <FormPageConfirmTermsTable
        ref="terms-table"
        :isEvent="false"
        :page_item="local_item"
        @changed="changed"
      />

    </div>
  </div>
</template>

<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import FormPageConfirmTermsTable from '@/view/pages/ml/forms/pages/confirm/FormPageConfirmTermsTable.vue';

import axios from 'axios';
import dayjs from 'dayjs';


export default {

  text: 'FormPageConfirmEditor',

  props: ['item','validate'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    FormPageConfirmTermsTable
  },

  watch: {
    
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.force_validate = this.force_validate + 1;
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        if (!this.local_item.fields) {
          this.local_item.fields = {
            terms: []
          };

          this.local_item = { ...this.local_item };

          this.$emit('change', this.local_item);
        }
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }

      if (!this.local_item.fields) {
        this.local_item.fields = {
          terms: []
        };

        this.local_item = { ...this.local_item };

        this.$emit('change', this.local_item);
      }
      

      /**
       * fields = {
       *   field_text: {
       *     show: true,
       *     required: false
       *   }
       * }
       */
    }
    
  },

  methods: {

    changed(item) {

      this.local_item = { ...item };

      this.$emit('change', this.local_item);
    },

    validate() {
      this.force_validate = this.force_validate + 1;
    },

    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      local_item: {},

      required_personnr: null,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

:deep .v-label {
  left: 9px;
  top: 3px;
}

</style>

<template>
  <div class="no-padding">
    
    <div v-if="local_item && local_item.company_select_labels" style="height: 100%;" class="mb-8">
      
      <v-row>
        <v-col lg="6">

          <memlist-text-input
            id="company_select_labels0"
            name="company_select_labels0"
            :title="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL0_LABEL')"
            layout="vertical"
            validation_type="TEXT"

            v-model="local_item.company_select_labels[0]"

            :placeholder="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL0_LABEL')"
            :required="false"
            
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6">

          <memlist-text-input
            id="company_select_labels1"
            name="company_select_labels1"
            :title="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL1_LABEL')"
            layout="vertical"
            validation_type="TEXT"

            v-model="local_item.company_select_labels[1]"

            :placeholder="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL1_LABEL')"
            :required="false"
            
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6">

          <memlist-text-input
            id="company_select_labels2"
            name="company_select_labels2"
            :title="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL2_LABEL')"
            layout="vertical"
            validation_type="TEXT"

            v-model="local_item.company_select_labels[2]"

            :placeholder="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL2_LABEL')"
            :required="false"
            
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>
      </v-row>

      <v-row>
        <v-col lg="6">

          <memlist-text-input
            id="company_select_labels3"
            name="company_select_labels3"
            :title="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL3_LABEL')"
            layout="vertical"
            validation_type="TEXT"

            v-model="local_item.company_select_labels[3]"
            
            :placeholder="$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL3_LABEL')"
            :required="false"
            
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>
      </v-row>
      
      <v-row>
        <v-col lg="6">

          <memlist-text-input
            id="company_select_labels4"
            name="company_select_labels4"
            :title="$t('PAGES.REGISTRATIONSETUP.HEADER_EXTRA_LABEL')"
            layout="vertical"
            validation_type="TEXT"

            v-model="local_item.company_select_labels[4]"

            :placeholder="$t('PAGES.REGISTRATIONSETUP.HEADER_EXTRA_LABEL')"
            :required="false"

            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>
      </v-row>

      

    </div>
  </div>
</template>

<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

export default {

  name: 'FormPageCompanySelectTextsComponent',

  props: ['texts','validate'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    
  },

  watch: {
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.force_validate = this.force_validate + 1;
      }
    },

    texts: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.texts };

        if (!this.local_item.company_select_labels || this.local_item.company_select_labels.length === 0) {
          this.local_item.company_select_labels = ['', '', '', '', '', '', '', '', '', ''];
        }
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },

  async mounted() {

    if (this.texts) {
      this.local_item = { ...this.texts }
    }

    if (!this.local_item.company_select_labels || this.local_item.company_select_labels.length === 0) {
      this.local_item.company_select_labels = ['', '', '', '', '', '', '', '', '', ''];
    }

  },

  methods: {

    validate() {
      this.force_validate = this.force_validate + 1;
    },

    update_form(field_name, value) {

      console.log('label updated', field_name, value);

      if (field_name === 'company_select_labels0') {
        this.local_item.company_select_labels[0] = value;
      }
      else if (field_name === 'company_select_labels1') {
        this.local_item.company_select_labels[1] = value;
      }
      else if (field_name === 'company_select_labels2') {
        this.local_item.company_select_labels[2] = value;
      }
      else if (field_name === 'company_select_labels3') {
        this.local_item.company_select_labels[3] = value;
      }
      else if (field_name === 'company_select_labels4') {
        this.local_item.company_select_labels[4] = value;
      }
      
      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      local_item: {},

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>

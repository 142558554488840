<template>
  <div class="no-padding">
    <div style="height: 100%;">
      
      <v-row>
        <v-col lg="6">

          <memlist-text-input
            id="name"
            name="name"
            :title="$t('FORM.NAME')"
            layout="vertical"
            validation_type="TEXT"

            v-model="local_item.name"
            :validate="force_validate"
            :placeholder="$t('FORM.NAME')"
            :required="false"
            :invalid_text="$t('FORM.NAME_REQUIRED')"
            @validated="validated"
            @update:value="update_form"
            >
          </memlist-text-input>

        </v-col>
      </v-row>

    </div>
  </div>
</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import { get_domain_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {

  name: 'FormPageCompanyDetailsEditor',

  props: ['item','validate'],
  emits: ['change'],
  mixins: [ toasts ],

  components: {
    
  },

  watch: {
    validate: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.force_validate = this.force_validate + 1;
      }
    },

    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

  },

  methods: {

    validate() {
      this.force_validate = this.force_validate + 1;
    },

    update_form(field_name, value) {

      this.local_item[field_name] = value;

      this.local_item = { ...this.local_item };

      this.$emit('change', this.local_item);
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

  },


  data() {
    return {

      local_item: {},

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>

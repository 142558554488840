<template>
  <b-modal size="xl" ref="form-edit-modal" hide-footer>
  
    <b-card>
      <b-form-group id="input-group-id" label="ID" label-for="input-id">
        <b-form-input
          id="input-id"
          v-model="local_term_item.id"
          type="text"
          required
          disabled
        ></b-form-input>
      </b-form-group>

      <div class="d-flex align-items-center mb-8">
        <label
          class="checkbox checkbox-lg checkbox-outline checkbox-success"
        >
          <input
            type="checkbox"
            name=""
            v-model="local_term_item.is_mandatory"
            @click="local_term_item.is_mandatory = !local_term_item.is_mandatory"
          />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer"
          >{{$t('COMMON.MANDATORY')}}</span
        >
      </div>


      <div class="d-flex align-items-center mb-8">
        <label
          class="checkbox checkbox-lg checkbox-outline checkbox-success"
        >
          <input
            type="checkbox"
            name=""
            v-model="local_term_item.is_active"
            @click="local_term_item.is_active = !local_term_item.is_active"
          />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer"
          >{{$t('COMMON.ACTIVE')}}</span
        >
      </div>

      <b-form-group id="input-group-name"
        :label="$t('COMMON.NAME')" label-for="input-name">
        <b-form-input id="input-name" v-model="local_term_item.name" type="text"></b-form-input>
      </b-form-group>


      <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{$t('PAGES.TERMS.TERMS_TEXT_INFO')}}</div>
      </div>


      <b-form-group id="input-group-descr"
        :label="$t('PAGES.TERMS.CHECKBOX_TEXT')" label-for="input-descr">
        <b-form-input id="input-descr" v-model="local_term_item.descr" type="text"></b-form-input>
      </b-form-group>



      <b-form-group id="html-group"
        :label="$t('PAGES.TERMS.TEXT')" label-for="html">
        <tiptap-vuetify
          data-app
          v-model="local_term_item.template"
          :extensions="extensions"
        />

      </b-form-group>

      <v-row>
        <v-col>
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.SAVE')"
            :spin="true"
            @clicked="save_term_clicked"
            @close="$refs['form-edit-modal'].hide()"
            ref="saveButton" />
        </v-col>
      </v-row>
      
    </b-card>


  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';


import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'FormPageConfirmTermEditorModal',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['created', 'updated'],
  components: {
    RightModalSaveAndCloseButtons,
    TiptapVuetify,
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {
    this.local_term_item = { ...this.item }
  },
  data() {
    return {
      local_term_item: {},
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  watch: {

    item: {
      deep: true,
      handler(val) {
        this.local_term_item = { ...this.item };
      }
    },

    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    async save_term_clicked() {

      try {
        if (!this.local_term_item.id) {
          await this.post_term(this.local_term_item);
        }
        else {
          await this.put_term(this.local_term_item.id, this.local_term_item);
        }
      }
      catch (err) {
        console.error('save_term_clicked error', err);
      }

      this.$refs['saveButton'].stop();
    },


    async post_term(data) {
      try {
        const res = await axios.post(`/company/term/${this.currentCompanyId}`, data);

        if (res.status === 201) {
          this.local_term_item = { ...res.data };

          this.$emit('created', res.data);

          this.$refs['form-edit-modal'].hide()
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_CREATE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_CREATE'));
      }
    },

    async put_term(id, term) {
      try {
        const res = await axios.put(`/company/term/${id}`, term);

        if (res.status === 204) {
          
          this.$emit('updated', term);

          this.$refs['form-edit-modal'].hide()
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.TERMS.UNABLE_UPDATE'));
      }
    },

    created(item) {
      this.$emit('created', item);
    },

    updated(item) {
      this.$emit('updated', item);
    },

    // add any methods we need
    show() {
      this.$refs['form-edit-modal'].show();
    },

    hide() {
      this.$refs['form-edit-modal'].hide();
    }
  }
};
</script>

<template>
  <b-modal size="xl" ref="form-edit-modal" hide-footer>

    <ErrorPopup
      :error="error_popup_message"
    />

    <v-row style="margin-top: 22px !important;">
      <v-col lg="6">

        <memlist-text-input
          id="name"
          name="name"
          :title="$t('FORM.PAGE_NAME')"
          layout="vertical"
          validation_type="TEXT"

          v-model="local_item.name"
          :validate="force_validate"
          :placeholder="$t('FORM.PAGE_NAME')"
          :required="false"
          :invalid_text="$t('FORM.NAME_REQUIRED')"
          @validated="validated"
          @update:value="update_form"
          >
        </memlist-text-input>

      </v-col>
    </v-row>
    

    <v-row style="margin-top: 22px !important;">
      <v-col md="6">

        <p class="mt-4">{{ $t('FORM.PAGE_TYPE') }}</p>

        <v-select
          v-model="local_item.type"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="type_options"
          outlined
          dense
        ></v-select>

      </v-col>
    </v-row>

    <FormPageConfirmEditor
      v-if="local_item.type === 'CONFIRM'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPagePersonnrEditor
      v-if="local_item.type === 'PERSONNR'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageQuestionsEditor
      v-if="local_item.type === 'QUESTIONS'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPagePersonDetailsEditor
      v-if="local_item.type === 'PERSON_DETAILS'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageFamilyEditor
      v-if="local_item.type === 'FAMILY'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageParentEditor
      v-if="local_item.type === 'PARENTS'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageCompanyDetailsEditor
      v-if="local_item.type === 'COMPANY_DETAILS'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageCompanySelectEditor
      v-if="local_item.type === 'COMPANY_SELECT'"
      :item="local_item"
      :form="form"
      :validate="force_validate"
      @change="change"
      @created="page_created"
    />

    <FormPageShopEditor
      v-if="local_item.type === 'SHOP'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageDonationEditor
      v-if="local_item.type === 'DONATION'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageTicketsEditor
      v-if="local_item.type === 'TICKETS'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPagePaymentEditor
      v-if="local_item.type === 'PAYMENT'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />


    <FormPageBankIDLoginEditor
      v-if="local_item.type === 'BANKID_LOGIN'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageBankIDSignEditor
      v-if="local_item.type === 'BANKID_SIGN'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <FormPageLoginEditor
      v-if="local_item.type === 'LOGIN'"
      :item="local_item"
      :validate="force_validate"
      @change="change"
    />

    <p class="mt-8">{{ $t('FORM.HTML') }}</p>
    <tiptap-vuetify
      data-app
      v-model="local_item.html"
      :extensions="extensions"
    />

    <p class="mt-8"></p>

    <v-row>
      <v-col>
        <RightModalSaveAndCloseButtons
          :text="$t('COMMON.SAVE')"
          :spin="true"
          @clicked="on_save_clicked"
          @close="$refs['form-edit-modal'].hide()"
          ref="saveButton" />
      </v-col>
    </v-row>


  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';


import ErrorPopup from '@/view/components/ErrorPopup.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';


import FormPagePersonDetailsEditor from '@/view/pages/ml/forms/pages/FormPagePersonDetailsEditor.vue';
import FormPagePersonnrEditor from '@/view/pages/ml/forms/pages/FormPagePersonnrEditor.vue';
import FormPageQuestionsEditor from '@/view/pages/ml/forms/pages/FormPageQuestionsEditor.vue';
import FormPageShopEditor from '@/view/pages/ml/forms/pages/FormPageShopEditor.vue';

import FormPageFamilyEditor from '@/view/pages/ml/forms/pages/FormPageFamilyEditor.vue';
import FormPageParentEditor from '@/view/pages/ml/forms/pages/FormPageParentEditor.vue';
import FormPageCompanySelectEditor from '@/view/pages/ml/forms/pages/FormPageCompanySelectEditor.vue';
import FormPageCompanyDetailsEditor from '@/view/pages/ml/forms/pages/FormPageCompanyDetailsEditor.vue';
import FormPageDonationEditor from '@/view/pages/ml/forms/pages/FormPageDonationEditor.vue';
import FormPageTicketsEditor from '@/view/pages/ml/forms/pages/FormPageTicketsEditor.vue';
import FormPagePaymentEditor from '@/view/pages/ml/forms/pages/FormPagePaymentEditor.vue';
import FormPageBankIDLoginEditor from '@/view/pages/ml/forms/pages/FormPageBankIDLoginEditor.vue';
import FormPageBankIDSignEditor from '@/view/pages/ml/forms/pages/FormPageBankIDSignEditor.vue';
import FormPageLoginEditor from '@/view/pages/ml/forms/pages/FormPageLoginEditor.vue';
import FormPageConfirmEditor from '@/view/pages/ml/forms/pages/FormPageConfirmEditor.vue';


import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';


export default {
  name: 'FormsPageEditorModal',
  mixins: [ toasts ],
  props: ['item','form'],
  emits: ['create', 'update'],
  components: {
    TiptapVuetify,
    ErrorPopup,
    RightModalSaveAndCloseButtons,
    FormPageQuestionsEditor,
    FormPagePersonnrEditor,
    FormPageShopEditor,
    FormPagePersonDetailsEditor,
    FormPageFamilyEditor,
    FormPageParentEditor,
    FormPageCompanySelectEditor,
    FormPageCompanyDetailsEditor,
    FormPageDonationEditor,
    FormPageTicketsEditor,
    FormPagePaymentEditor,
    FormPageBankIDLoginEditor,
    FormPageBankIDSignEditor,
    FormPageLoginEditor,
    FormPageConfirmEditor
  },

  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    type_options() {

      let options = [
        { text: this.$t('FORM.PAGE_TYPES.PERSONNR'), value: 'PERSONNR' },
        { text: this.$t('FORM.PAGE_TYPES.QUESTIONS'), value: 'QUESTIONS' },
        { text: this.$t('FORM.PAGE_TYPES.PERSON_DETAILS'), value: 'PERSON_DETAILS' },
        { text: this.$t('FORM.PAGE_TYPES.FAMILY'), value: 'FAMILY' },
        { text: this.$t('FORM.PAGE_TYPES.PARENTS'), value: 'PARENTS' },
        { text: this.$t('FORM.PAGE_TYPES.COMPANY_SELECT'), value: 'COMPANY_SELECT' },
        { text: this.$t('FORM.PAGE_TYPES.COMPANY_DETAILS'), value: 'COMPANY_DETAILS' },
        { text: this.$t('FORM.PAGE_TYPES.SHOP'), value: 'SHOP' },
        { text: this.$t('FORM.PAGE_TYPES.DONATION'), value: 'DONATION' },
        { text: this.$t('FORM.PAGE_TYPES.TICKETS'), value: 'TICKETS' },
        { text: this.$t('FORM.PAGE_TYPES.PAYMENT'), value: 'PAYMENT' },
        { text: this.$t('FORM.PAGE_TYPES.BANKID_LOGIN'), value: 'BANKID_LOGIN' },
        { text: this.$t('FORM.PAGE_TYPES.BANKID_SIGN'), value: 'BANKID_SIGN' },
        { text: this.$t('FORM.PAGE_TYPES.LOGIN'), value: 'LOGIN' },
        { text: this.$t('FORM.PAGE_TYPES.CONFIRM'), value: 'CONFIRM' }
      ]

      options = options.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

      return options;
    },
    
  },
  mounted() {
    
    if (this.item) {
      this.local_item = { ...this.item }
    }

    if (!this.local_item.name) {
      this.local_item.name = 'Test';
    }
  },
  data() {
    return {
      spinning: false,
      show_error: false,
      is_form_valid: false,
      error_popup_message: null,
      local_item: {},
      force_validate: 0,
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  watch: {

    item: {
      handler(newValue) {
        this.local_item = { ...newValue };

        if (!this.local_item.name) {
          this.local_item.name = 'Test';
        }
      },
      deep: true
    },

    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    page_created(page) {
      this.$emit('created', page);
    },

    change(item) {
      this.local_item = { ...item };
    },

    update_form(field_name, value) {
      this.local_item[field_name] = value;
      this.local_item = { ...this.local_item };
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_save_clicked() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(async ()=>{

        try {
          if (this.is_form_valid) {
            this.spinning = false;

            if (this.local_item.id) {
              this.$emit('update', this.local_item);
            }
            else {
              this.$emit('create', this.local_item);
            }
            
          }
          else {
            this.show_error = true; 
          }
        }
        catch (err) {
          console.error('on_save_clicked', err);
        }

        setTimeout(()=>{
          this.spinning = false;

          this.$refs['saveButton'].stop();
        }, 500);
        
      });
    },

    // add any methods we need
    show() {
      this.$refs['form-edit-modal'].show();
    },

    hide() {
      this.$refs['form-edit-modal'].hide();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
